import BasePlugin from '../BasePlugin'

export default class CreateAdjustmentRequestByDecisionAndScenario extends BasePlugin {
  async execute () {
    let investmentRequestId = this.context.getParentContext().getModel().id
    let scenarioId = this.context.getModel().scenario
    let decisionId = this.context.getModel().decision

    await this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SendForApprovalInvestmentRequestCommand`,
      { 'investmentRequestId': investmentRequestId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    await this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddIntegrationNumbersForInvestmentExpensesBudgetAndInvestmentBudgetCommand`,
      { 'investmentRequestId': investmentRequestId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    await this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CreateAdjustmentRequestAfterInvestmentRequestApprovalCommand`,
      { 'investmentRequestId': investmentRequestId, 'async': 'true', 'decisionId': decisionId, 'scenarioId': scenarioId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    this.context.$msgbox.close()
    this.context.getParentContext().getCard().$emit('cancelChanges')
  }
}
